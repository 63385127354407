import './App.css';
import "./bootstrap.min.css"
import Home from "./screens/home"
import About from './screens/about';
import Contact from './screens/contact';
import EventMain from './screens/eventMain';
import EventInner from './screens/eventInner';
import Blog from './screens/blog';
import IdvBlog from "./screens/IdvBlog"
import VendorBooking from './screens/vendorBooking';
import { Routes, Route } from 'react-router-dom';
import UserCheckout from "./screens/UserCheckout"
import Footer from "./component/Footer/Footer"


import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";

const matrimonialInfo = {
  img:"/images/creative/matrimonial-content-img.png",
  subHeading:"BEST MATRIMONIAL EVENT",
  title:"FIND YOUR PERFECT MATCH AT MUSLIM MATRIMONIAL",
  content:"He made you in pairs, and we can help you find them, connect with them, and create an everlasting bond. Participants are expected to register their interest in the event and show up with the sole objective of getting married along with their families. Meetings with people of the opposing gender are set up using profile cards and brief, topical group introductions.",
  btnRoute:"contact",
  borderColor:"yellow",
  listPoint:["Meet your potential life partner","A cultural environment and halal environment","A safe and welcoming space","Participating in enriching activities","Experienced and personalized matchmaking services","Take care to protect your privacy","Commitment to values"],
  meta:{
    title:"Muslim Matrimonial Event | Find Your Perfect Match",
    desc:"Join the best matrimonial event to meet your perfect match. Experience a cultural and halal environment with personalized matchmaking services."
  }
}
const azadiInfo = {
  img:"/images/creative/azadi-Festival-content-img.png",
  subHeading:"BEST AZADI FESTIVAL",
  title:"AZADI FESTIVAL CELEBRATION LIKE NO OTHER",
  content:"Don't miss the extraordinary celebration of freedom. Immerse yourself in cultural richness and joy, with events meticulously crafted for unity. From vibrant displays of tradition to modern expressions of freedom, this festival captures the essence of Azadi in every detail. Join us in commemorating this momentous occasion with a fusion of festivities that truly make it the best Azadi Festival yet!",
  btnRoute:"contact",
  borderColor:"pink",
  listPoint:["Diverse Cultural Show","Stalls for Shopping","Culinary Delights from Traditions","Artisan Markets for Authentic Crafts","Engaging Activities for All Ages","Inspirational Speaker Sessions","Unforgettable Community Bonds"],
  meta:{
    title:"Azadi Festival: Celebration of Freedom | Best Azadi Festival",
    desc:"Experience the best Azadi Festival celebration like no other! Immerse yourself in cultural richness, culinary delights, and engaging activities for all ages."
  }
}

const muslimInfo={
  img:"/images/creative/Muslim-Fest-content-img.png",
  subHeading:"TORONTO MUSLIM FEST",
  title:"TIME TO HAVE FUN AT MUSLIMFEST",
  content:"Get ready for a vibrant celebration of culture and community at the Toronto Muslim Fest. This event promises a day filled with entertainment, cultural performances, delicious food, and opportunities to connect with fellow members of the Muslim community in Toronto. In addition to promoting the arts and entertainment, Muslim Fest has made a significant contribution to highlighting the diversity of Islamic culture and identity. The convergence of religion and culture is honored at the Muslim Fest.",
  btnRoute:"contact",
  borderColor:"blue",
  listPoint:["Enjoy live performances from new artists","Shop around the stalls","Buy your favorite jewelry and clothes","Enjoy cultural food","Get your hands done with mehendi","Enjoy group activities with your community","Celebrate diversity"],
  meta:{
    title:"Toronto Muslim Fest | Cultural Celebration & Community Event",
    desc:"Join us for a vibrant celebration of culture and community at the Toronto Muslim Fest. Enjoy entertainment, cultural performances, food, and more. Celebrate diversity with us!"
  }
}
const foodInfo={
  img:"/images/creative/Food-Fest-content-img.png",
  subHeading:"BEST FOOD FESTIVALS",
  title:"Beyond Taste Buds: Flavorful Food Festival",
  content:"SIPRA Promotions elevates cultural experiences through food festivals, blending culinary delights with vibrant traditions. From traditional favorites to modern interpretations, our events bring together a tapestry of tastes, aromas, and cultural expressions. Join us in savoring the essence of Pakistani culinary traditions, fostering community connections, and creating unforgettable moments that celebrate the joy of food and shared experiences.",
  btnRoute:"contact",
  borderColor:"blue",
  listPoint:["Cuisines and Delicacies" ,"Delicious Halal Food","Bazaar & Shopping","Kids' Activities","Video and photography","Live performances"],
  meta:{
    title:"Flavorful Food Festival | Cultural Culinary Experience",
    desc:"Experience the essence of Pakistani culinary traditions at our food festival. Join us for delicious halal food, cultural performances, and family-friendly activities."
  }
}
const eidInfo={
  img:"/images/creative/Eid-Fest-content-img.png",
  subHeading:"BEST EID FESTIVAL",
  title:"Sipra’s Timeless Eid festival celebration",
  content:"Experience the pinnacle of celebration with SIPRA Promotions' Best Eid Festival. Immerse yourself in a vibrant blend of tradition and innovation, as we curate an unforgettable event filled with joy, cultural richness, and inclusive festivities. From captivating performances to mouth-watering cuisine, join us in creating cherished memories and fostering unity. SIPRA's commitment ensures the Best Eid Festival is an unparalleled celebration for everyone.",
  btnRoute:"contact",
  borderColor:"yellow",
  listPoint:["Traditional clothes","Mehndi stalls","Video and photography","Live performances","Delicious Halal Food","Bazaar & Shopping","Kid's Activities"],
  meta:{
    title:"Best Eid Festival Celebration | Scarborough, April 6-9, 2024",
    desc:"Join SIPRA Promotions for the Best Eid Festival in Scarborough. Experience rich traditions, live performances, Halal food, and family activities."
  }

}

function App() {
  return (
    <div style={{backgroundColor:"#060E19"}}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/event" element={<EventMain />} />
        <Route path="/news" element={<Blog />} />
        <Route path="/blogs/:id" element={<IdvBlog />} />
        <Route path="/vendor" element={<VendorBooking />} />
        <Route path='/successful-checkout' element={<UserCheckout />} />

        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />

        {/* Events individual */}
        <Route path="/matrimonial-festival" element={
          <EventInner 
            heroImg="/images/creative/Matrimoial-hero-bg.jpg"
            heroHeading="MUSLIM "
            heroHeadingSpan="MATRIMONAL"
            heroSubHeading=""
            heroDate={{date:"7-8",month:"OCTOBER",year:"2023"}}
            heroDateContainer={false}
            eventInfo={matrimonialInfo}
            meta={matrimonialInfo.meta}
            targetDate={{ date: "25th", month: "AUGUST", year: "2024" }}
            ticketBookingSection={true}
            ticketData={
            [
              {
                borderColor:"yellow",
                btnText:"TICKETS",
                btnColor:"blue",
                date:{day:"25",month:"August"},
                eventName:"Muslim Matrimonial",
                eventText:"at Chinese Cultural Centre",
                eventLocation:"5183 Sheppard Avenue East Toronto, ON M1B 5Z5",
                eventTime:"3:00AM - 07:00PM",
                btnLink:"https://www.eventbrite.ca/e/muslim-matrimonial-tickets-947241243057",
              },
              {
                borderColor:"pink",
                btnText:"TICKETS",
                btnColor:"pink",
                date:{day:"19",month:"May"},
                eventName:"Muslim Matrimonial",
                eventText:"Muslim Matrimonial",
                eventLocation:"The Estate Banquet Hall",
                eventTime:"11:00AM - 04:00PM",
                // btnLink:"https://www.eventbrite.ca/e/muslim-matrimonial-event-tickets-891331134417?aff=oddtdtcreator",
              },
              {
                borderColor:"blue",
                btnText:"TICKETS",
                btnColor:"pink",
                date:{day:"1st",month:"June"},
                eventName:"Muslim Matrimonial",
                eventText:"Muslim Matrimonial",
                eventLocation:"1325 Eglinton Ave E",
                eventTime:"10:30AM - 03:30PM",
                // btnLink:"https://www.eventbrite.ca/e/muslim-matrimonial-event-tickets-891337804367?aff=oddtdtcreatora",
              }
          ]}
          />
        } />

        <Route path="/azadi-festival" element={
          <EventInner 
            heroImg="/images/creative/Azadi-Fest-hero-bg.jpg"
            heroHeading="AZADI "
            heroHeadingSpan="FESTIVAL"
            heroSubHeading=""
            heroDate={{date:"26th",month:"AUGUST",year:"2023"}}
            eventInfo={azadiInfo}
            meta={azadiInfo.meta}
            targetDate={{ date: "25th", month: "AUGUST", year: "2024" }}
            ticketBookingSection={true}
            ticketData={
              [
                {
                  borderColor:"yellow",
                  btnText:"TICKETS",
                  btnColor:"blue",
                  date:{day:"25",month:"August"},
                  eventName:"AZADI FESTIVAL",
                  eventText:"AZADI FESTIVAL",
                  eventLocation:"5183 Sheppard Ave E, Scarborough, ON M1B 5Z5",
                  eventTime:"10:00PM - 12:00PM",
                  btnLink:"tel:416-577-0055",
                },
              ]
            }
          />
        } />
        <Route path="/muslim-festival" element={
          <EventInner 
            heroImg="/images/creative/Muslim-Fest-hero-bg.jpg"
            heroHeading="TORONTO MUSLIM "
            heroHeadingSpan="FESTIVAL"
            heroSubHeading=""
            heroDateContainer={false}
            heroDate={{date:"26th",month:"AUGUST",year:"2023"}}
            eventInfo={muslimInfo}
            meta={muslimInfo.meta}
          />
        } />
        <Route path="/eid-festival" element={
          <EventInner 
            heroImg="/images/creative/Eid-Festival-hero-bg.jpg"
            heroHeading="EID "
            heroHeadingSpan="FESTIVAL"
            heroSubHeading="East York Town Centre, 45 overlea Blvd. Toronto, M4H1C3"
            heroDateContainer={true}
            heroDate={{date:"21st",month:"MARCH",year:"2025"}}
            targetDate={{ date: "21st", month: "MARCH", year: "2025" }}
            ticketBookingSection={true}
            eventInfo={eidInfo}
            meta={eidInfo.meta}
            ticketData={

 [
  {
    borderColor: "yellow",
    btnText: "Call",
    btnColor: "blue",
    date: { day: "21", month: "March" },
    eventName: "Eid Festival",
    eventText: "2025",
    eventLocation: "East York Town Centre, 45 Overlea Blvd. Toronto, M4H1C3",
    eventTime: "10:00AM - 08:00PM",
    btnLink: "tel:416-577-0055"
  },
  {
    borderColor: "pink",
    btnText: "Call",
    btnColor: "blue",
    date: { day: "22", month: "March" },
    eventName: "Eid Festival",
    eventText: "2025",
    eventLocation: "East York Town Centre, 45 Overlea Blvd. Toronto, M4H1C3",
    eventTime: "10:00AM - 06:00PM",
    btnLink: "tel:416-577-0055"
  },
  {
    borderColor: "blue",
    btnText: "Call",
    btnColor: "blue",
    date: { day: "27", month: "March" },
    eventName: "Eid Festival",
    eventText: "2025",
    eventLocation: "The Estate Banquet Hall, 430 Nugget Ave, Scarborough, M1S 4A4",
    eventTime: "12:00PM - 12:00AM",
    btnLink: "tel:416-577-0055"
  },
  {
    borderColor: "yellow",
    btnText: "Call",
    btnColor: "blue",
    date: { day: "28", month: "March" },
    eventName: "Eid Festival",
    eventText: "2025",
    eventLocation: "The Estate Banquet Hall, 430 Nugget Ave, Scarborough, M1S 4A4",
    eventTime: "12:00PM - 12:00AM",
    btnLink: "tel:416-577-0055"
  },
  {
    borderColor: "pink",
    btnText: "Call",
    btnColor: "blue",
    date: { day: "29", month: "March" },
    eventName: "Eid Festival",
    eventText: "2025",
    eventLocation: "The Estate Banquet Hall, 430 Nugget Ave, Scarborough, M1S 4A4",
    eventTime: "12:00PM - 12:00AM",
    btnLink: "tel:416-577-0055"
  }
]}
          />
          
        } />
        <Route path="/food-festival" element={
          <EventInner 
            heroImg="/images/creative/Food-Fest-hero-bg.jpg"
            heroHeading="FOOD "
            heroHeadingSpan="FESTIVAL"
            heroSubHeading=""
            heroDate={{date:"26th",month:"AUGUST",year:"2023"}}
            eventInfo={foodInfo}
            meta={foodInfo.meta}
          />
        } />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
