import React from 'react'
import EventBox from './EventBox'
import "./UpcomingEvent.css"

function UpcomingEvent() {
  return (
    <div className='up-event-container text-center container standard-padding-space' id='ticketbook' >
        <h3 className='body-heading'>past & upcoming <span className='color-yellow'>events</span></h3>
        <p className='body-paragraph'>Upcoming events over magna eget est lorem ipsum dolor sit amet consectetur.</p>
        <div className='px-1 px-md-0'>
        <EventBox 
            borderColor="yellow"
            btnText="Call"
            btnColor="blue"
            date={{day:"21",month:"March"}}
            eventName="Eid Festival"
            eventText="2025"
            eventLocation="East York Town Centre, 45 overlea Blvd. Toronto, M4H1C3"
            eventTime="10:00AM - 08:00PM"
            btnLink="tel:416-577-0055"
        />
        <EventBox 
            borderColor="pink"
            btnText="Call"
            btnColor="blue"
            date={{day:"22",month:"March"}}
            eventName="Eid Festival"
            eventText="2025"
            eventLocation="East York Town Centre, 45 overlea Blvd. Toronto, M4H1C3"
            eventTime="10:00AM - 06:00PM"
            btnLink="tel:416-577-0055"
        />
        <EventBox 
            borderColor="blue"
            btnText="Call"
            btnColor="blue"
            date={{day:"27",month:"March"}}
            eventName="Eid Festival"
            eventText="2025"
            eventLocation="The Estate Banquet Hall, 430 Nugget Ave, Scarborough, M1S 4A4"
            eventTime="12:00PM - 12:00AM"
            btnLink="tel:416-577-0055"
        />
        <EventBox 
            borderColor="yellow"
            btnText="Call"
            btnColor="blue"
            date={{day:"28",month:"March"}}
            eventName="Eid Festival"
            eventText="2025"
            eventLocation="The Estate Banquet Hall, 430 Nugget Ave, Scarborough, M1S 4A4"
            eventTime="12:00PM - 12:00AM"
            btnLink="tel:416-577-0055"
        />
        <EventBox 
            borderColor="pink"
            btnText="Call"
            btnColor="blue"
            date={{day:"29",month:"March"}}
            eventName="Eid Festival"
            eventText="2025"
            eventLocation="The Estate Banquet Hall, 430 Nugget Ave, Scarborough, M1S 4A4"
            eventTime="12:00PM - 12:00AM"
            btnLink="tel:416-577-0055"
        />
        <EventBox 
            borderColor="yellow"
            btnText="TICKETS"
            btnColor="blue"
            date={{day:"25",month:"August"}}
            eventName="Muslim Matrimonial"
            eventText="at Chinese Cultural Centre"
            eventLocation="5183 Sheppard Avenue East Toronto, ON M1B 5Z5"
            eventTime="3:00AM - 07:00PM"
            btnLink="https://www.eventbrite.ca/e/muslim-matrimonial-tickets-947241243057"
        />
        <EventBox 
            borderColor="yellow"
            btnText="TICKETS"
            btnColor="blue"
            date={{day:"25",month:"August"}}
            eventName="AZADI FESTIVAL"
            eventText="AZADI FESTIVAL"
            eventLocation="5183 Sheppard Ave E, Scarborough, ON M1B 5Z5"
            eventTime="10:00PM - 12:00PM"
            btnLink="tel:416-577-0055"
        />
        <EventBox 
            borderColor="pink"
            btnText="TICKETS"
            btnColor="pink"
            date={{day:"19",month:"May"}}
            eventName="Muslim Matrimonial"
            eventText="Muslim Matrimonial"
            eventLocation="The Estate Banquet Hall"
            eventTime="11:00AM - 04:00PM"
            // btnLink="https://www.eventbrite.ca/e/muslim-matrimonial-event-tickets-891331134417?aff=oddtdtcreator"
        />
       <EventBox 
            borderColor="pink"
            btnText="TICKETS"
            btnColor="pink"
            date={{day:"1st",month:"June"}}
            eventName="Muslim Matrimonial"
            eventText="Muslim Matrimonial"
            eventLocation="1325 Eglinton Ave E"
            eventTime="10:30AM - 03:30PM"
            // btnLink="https://www.eventbrite.ca/e/muslim-matrimonial-event-tickets-891337804367?aff=oddtdtcreatora"
        />
        <EventBox 
            borderColor="pink"
            btnText="TICKETS"
            btnColor="pink"
            date={{day:"07",month:"June"}}
            eventName="Eid Festival"
            eventText="Eid Festival"
            eventLocation="East York Center"
            eventTime="10:00AM - 08:00PM"
        />
        
        </div>
    </div>
  )
}

export default UpcomingEvent