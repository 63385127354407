import React from 'react'
import "./Sponsor.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function Sponsor() {
  return (
    <div className='sponsor-container standard-padding-space'>
      <div className='container'>
        
        <h3 className='body-heading'><span className='color-blue'>OUR SPONSORS</span></h3>
        <p className='body-paragraph w-50 mx-auto mt-4'>SIPRA Promotions expresses sincere gratitude for the generous contributions from our sponsors and look forward to continued partnerships that help us serve the community better.</p>
        <div className="companies-logo-box w-100">
        <Swiper
            spaceBetween={50}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                }
              }}
            className=" py-3 mx-auto"
            >
                <SwiperSlide>
                <img src="images/icons/logo-1.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-2.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-3.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-4.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-5.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-6.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-7.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-8.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-9.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-10.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-11.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-12.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-13.webp" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-14.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-15.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-16.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="images/icons/logo-17.png" alt="" />
                </SwiperSlide>
                
            </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Sponsor